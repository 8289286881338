.contactNav {
  padding: 2rem 0rem;
  border-bottom: 1px solid var(--border-color);

  & > .wrapper {
    margin: auto;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Montserrat;
    padding: 0rem 2rem;

    & > .eachContact {
      display: flex;
      align-items: center;
      gap: 15px;

      & > img {
        height: 100%;
        margin: auto 0;
      }

      & > .content {
        & > .title {
          font-size: 1.2rem;
          font-weight: 550;
          margin-bottom: 5px;
        }

        & > .desc {
          font-size: 0.9rem;
        }
      }
    }
  }
}
