.navbar {
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 0rem;

  & > .wrapper {
    margin: auto;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 2rem;

    & > .navOptions {
      display: flex;
      gap: 1rem;
      align-items: center;

      & > .navigations {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-right: 1rem;
        font-size: 0.9rem;

        & > .eachNav {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          position: relative;
          transition: all linear 0.3s;

          &::before {
            content: "";
            position: absolute;
            height: 1.5px;
            background: var(--main-pink);
            bottom: -0.65rem;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            transition: all linear 0.3s;
          }

          &:hover {
            &::before {
              width: 100%;
            }
          }
        }

        & > .selectedNav {
          font-weight: 500;

          &::before {
            width: 100%;
          }
        }
      }

      & > .submitBtn {
        padding: 1rem 1.5rem;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid var(--main-pink);
        font-size: 0.85rem;
        font-weight: 500;
        transition: all linear 0.3s;

        &:hover {
          background: var(--main-pink);
          color: white;
        }
      }
    }
  }
}
