.main {
  & > .wrapper {
    margin: auto;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 2rem;

    & > .content {
      font-family: Montserrat Alternates;

      & > .title {
        font-size: 2.5rem;
        font-weight: 550;
        max-width: 550px;

        & > span {
          color: var(--main-pink);
        }
      }

      & > .desc {
        font-size: 1.4rem;
        font-weight: 450;
        max-width: 550px;
        margin-top: 1.5rem;
      }
    }

    & > img {
      height: 100%;
    }
  }
}
